import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Nav from '../components/NavSub'
import Layout from '../components/layout'
import Header from '../components/Header'
import coverPhoto from '../assets/images/banner-8.jpg'

class ServicesChildAssessments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Child Assessments - Carien Zeeman</title>
          <meta name="description" content="Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="info" className="main">
            <div className="content sub-section">
              <header className="major">
                <h2>Child Assessments</h2>
              </header>
              <span className="image main">
                <img src={coverPhoto} alt="" />
              </span>
              <p>Carien conducts a child assessment in a holistic and unbiased manner. During the child’s assessment, she will strive to gain a better understanding of the child’s world in pursuance to develop an understanding of the child’s emotional and behavioural presentations. Depending on the need(s) of the child, the focus of the assessment could fall on:</p>
              <li>Developmental milestones</li>
              <li>Experiences at home</li>
              <li>Relationships with and experiences of parents and siblings</li>
              <li>Experience of parents’ relationship</li>
              <li>Relationships with friends and significant others</li>
              <li>Relationships in which the child may experience uneasiness</li>
              <li>Attachment</li>
              <li>Screening for possible traumatic experiences</li>
              <li>Experiences of school</li>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ServicesChildAssessments
